import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.timers.js";
export default {
  __name: 'CasesTopMenu',
  setup: function setup(__props) {
    var TopMedia = function TopMedia() {
      return import('~/components/navigation/TopMedia.vue');
    };
    var TopJobs = function TopJobs() {
      return import('~/components/navigation/TopJobs.vue');
    };
    var TopLearning = function TopLearning() {
      return import('~/components/navigation/TopLearning.vue');
    };
    var TopCommunity = function TopCommunity() {
      return import('~/components/navigation/TopCommunity.vue');
    };
    var TopAbout = function TopAbout() {
      return import('~/components/navigation/TopAbout.vue');
    };
    var TopVideo = function TopVideo() {
      return import('~/components/navigation/TopVideo.vue');
    };
    var routesToHide = ['learning-course-slug', 'learning-course-course-module-module', 'learning-course-course-module-module-test', 'learning-course-course-module-module-article', 'learning-results-id', 'conference-cases-product-design-2021', 'conference-cases-marketing-strategy-pr-2021', 'conference-cases-product-design-2022', 'cases-design-challenge', 'cases-special-event', 'login', 'registration', 'forgotpassword', 'changepassword', 'editor(-.*)?'];
    var $route = useRoute();
    var hideMenu = computed(function () {
      if (['company', 'profile', 'events'].includes(activeApp.value)) return true;
      if (!activeApp.value) return true;
      var routeName = $route.name;
      if (!routeName) return false;
      var testRoute = function testRoute(r) {
        return new RegExp(r + '___').test(routeName);
      };
      return routesToHide.some(testRoute);
    });
    var appStore = useAppStore();
    var activeApp = computed(function () {
      return appStore.activeApp;
    });

    // Pravent bottom line at active item from flickering on initial page load 
    var enableTransition = ref(false);
    onMounted(function () {
      setTimeout(function () {
        enableTransition.value = true;
      }, 100);
    });
    return {
      __sfc: true,
      TopMedia: TopMedia,
      TopJobs: TopJobs,
      TopLearning: TopLearning,
      TopCommunity: TopCommunity,
      TopAbout: TopAbout,
      TopVideo: TopVideo,
      routesToHide: routesToHide,
      $route: $route,
      hideMenu: hideMenu,
      appStore: appStore,
      activeApp: activeApp,
      enableTransition: enableTransition
    };
  }
};